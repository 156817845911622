<template>
    <v-app-bar
        color="white"
        app
        dense
        clipped-right
        :clipped-left="!$route.params.sid"
        height="48px"
        flat
        data-intro="You can now directly move between organizations, spaces, instances and states using the dropdowns in the toolbar."
        data-step="1"
        class="toolbar-border-bottom"
    >
        <div v-if="!$route.params.sid" class="d-flex align-center">
            <v-toolbar-title class="pl-0 flex-shrink-0">
                <router-link v-if="$vuetify.breakpoint.smAndUp === true" :to="{ name: 'home-welcome' }" style="text-decoration: none;">
                    <a class="primary--text ma-0 pa-0" style="min-width:200px">
                        <v-hover v-slot:default="{ hover }">
                            <div class="d-flex align-center ml-1">
                                <v-img
                                    class="ml-2 mr-1"
                                    :style="hover ? 'filter: grayscale(100%)' : ''"
                                    contain
                                    max-width="110px"
                                    max-height="23"
                                    src="@/assets/Nuvolos-white.svg"
                                />
                            </div>
                        </v-hover>
                    </a>
                </router-link>
            </v-toolbar-title>
        </div>
        <v-divider v-if="!$route.params.sid" class="ml-3" inset vertical></v-divider>
        <BreadcrumbsLargeScreen v-if="$vuetify.breakpoint.mdAndUp" />
        <BreadcrumbsSmallScreen v-else />
        <v-spacer />
        <div v-if="$vuetify.breakpoint.mdAndUp && $auth.isAuthenticated()" class="d-flex align-center justify-space-around flex-shrink-0">
            <v-menu v-if="userInfo && Object.keys(userInfo).length > 0" :close-on-content-click="true" :nudge-width="150" offset-y left class="mt-1">
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn :small="$route.name === 'app-open'" v-on="{ ...tooltip, ...menu }" text>
                                <v-badge v-if="runningDeployments.length" color="secondary" right overlap>
                                    <template v-slot:badge>
                                        <v-fab-transition>
                                            <span v-text="runningDeployments.length" fab></span>
                                        </v-fab-transition>
                                    </template>
                                    <v-icon color="primary">desktop_windows</v-icon>
                                </v-badge>
                                <v-icon v-else color="primary">desktop_windows</v-icon>
                            </v-btn>
                        </template>
                        <span>Running Apps</span>
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text subtitle-1 mt-3 font-weight-bold">Running Applications</v-list-item-title>
                            <v-list-item-subtitle>{{ runningDeployments.length }} applications found</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="runningDeployments.length" class="mt-3 mb-3"></v-divider>
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="app in runningDeployments"
                            :key="app.aid"
                            :to="{ name: 'app-open', params: { oid: app.oid, sid: app.sid, iid: app.iid, snid: app.snid, aid: app.aid } }"
                        >
                            <v-list-item-avatar>
                                <img :src="getAppLink(app.app_type)" />
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>
                                    <h4 class="primary--text">{{ app.name }}</h4>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <v-divider></v-divider>
            </v-menu>
            <v-menu :close-on-content-click="true" :nudge-width="150" offset-y left class="mt-1">
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip, ...menu }" text>
                                <v-badge v-if="runningUserTasks.length" color="secondary" right overlap>
                                    <template v-slot:badge>
                                        <v-fab-transition>
                                            <span v-text="runningUserTasks.length" fab></span>
                                        </v-fab-transition>
                                    </template>
                                    <v-icon color="primary">notifications</v-icon>
                                </v-badge>
                                <v-icon v-else color="primary">notifications</v-icon>
                            </v-btn>
                        </template>
                        <span>Running tasks</span>
                    </v-tooltip>
                </template>
                <v-list>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text subtitle-1 font-weight-bold">Running Tasks</v-list-item-title>
                            <v-list-item-subtitle class="caption font-weight-medium">{{ runningUserTasks.length }} tasks found</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$route.name !== 'user-tasks'">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-btn exact :to="{ name: 'user-tasks' }" small outlined text>show all tasks</v-btn>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu offset-y left>
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn :small="$route.name === 'app-open'" color="primary" text v-on="{ ...tooltip, ...menu }">
                                <v-icon>mdi-lifebuoy</v-icon>
                            </v-btn>
                        </template>
                        <span>Help</span>
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-list-item @click="openDocs()">
                        <v-list-item-action>
                            <v-icon small>help_outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Documentation</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showIntercomChat">
                        <v-list-item-action>
                            <v-icon small>mdi-lifebuoy</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Ask for help</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu max-width="200" offset-y>
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn :small="$route.name === 'app-open'" color="primary" text v-on="{ ...tooltip, ...menu }">
                                <v-icon>account_circle</v-icon>
                            </v-btn>
                        </template>
                        <span>Account</span>
                    </v-tooltip>
                </template>
                <v-list nav dense>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text subtitle-1 mt-3 font-weight-bold">Account & Settings</v-list-item-title>
                            <v-list-item-subtitle>{{ userInfo ? userInfo.given_name : '' }} {{ userInfo ? userInfo.family_name : '' }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-3 mb-3"></v-divider>

                    <v-list-item @click="openSettings()">
                        <v-list-item-title class="subtitle-2 primary--text">Settings</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout()">
                        <v-list-item-title class="subtitle-2 primary--text">Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <div v-else-if="$auth.isAuthenticated()">
            <v-menu offset-y offset-overflow :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                        <v-icon>menu</v-icon>
                    </v-btn>
                </template>
                <v-expansion-panels accordion focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="caption primary--text font-weight-bold">
                            <div class="d-flex justify-start">
                                <v-icon class="mr-1" small>account_circle</v-icon>
                                Account & Settings
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense>
                                <v-list-item @click="openSettings()">
                                    <v-list-item-title class="subtitle-2 primary--text d-flex align-center">
                                        <v-icon small class="mr-1">settings</v-icon>User Settings
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="logout()">
                                    <v-list-item-title class="subtitle-2 primary--text d-flex align-center">
                                        <v-icon class="mr-1" small>exit_to_app</v-icon>
                                        Logout
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="caption primary--text font-weight-bold">
                            <div class="d-flex justify-start">
                                <v-icon class="mr-1" small>desktop_windows</v-icon>
                                Running Applications
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list-item-group color="primary">
                                <v-list-item
                                    v-for="app in runningDeployments"
                                    :key="app.aid"
                                    :to="{ name: 'app-open', params: { oid: app.oid, sid: app.sid, iid: app.iid, snid: app.snid, aid: app.aid } }"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="subtitle-2 primary--text d-flex justify-start align-center">
                                                <img width="20" height="20" :src="getAppLink(app.app_type)" />
                                                <span class="ml-1">{{ app.name }}</span>
                                            </div>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="caption primary--text font-weight-bold">
                            <div class="d-flex justify-start">
                                <v-icon class="mr-1" small>mdi-lifebuoy</v-icon>
                                Help
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense>
                                <v-list-item @click="openDocs()">
                                    <v-list-item-action>
                                        <v-icon small>help_outline</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>Documentation</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-menu>
        </div>
    </v-app-bar>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import store from 'store'
import { enumsData } from '@/mixins/enums'
import { intercomMethods } from '@/mixins/intercom'

const BreadcrumbsLargeScreen = () => import('./BreadcrumbsLargeScreen')
const BreadcrumbsSmallScreen = () => import('./BreadcrumbsSmallScreen')

export default {
    components: {
        BreadcrumbsLargeScreen,
        BreadcrumbsSmallScreen
    },
    mixins: [appTypeAndImageLink, enumsData, intercomMethods],
    data: function() {
        return {
            userProfileItems: [{ title: 'Logout' }],
            appName: '',
            height: 70,
            organization: ''
        }
    },
    methods: {
        openDocs: function() {
            window.open('https://docs.nuvolos.cloud', '_blank')
        },
        openSettings: function() {
            this.$router.push({ name: 'user-profile' })
        },
        reloadPage() {
            location.reload()
        },
        logout() {
            store.remove('nvTarget')
            this.$auth.logOut()
        }
    },
    computed: {
        ...mapGetters('appStore', ['runningDeployments']),
        ...mapState(['userInfo']),
        ...mapState('userStore', ['userTasks']),
        runningUserTasks() {
            if (this.userTasks) {
                const runningTasksOnly = this.userTasks.filter(task => task.status === this.$data.taskStatusTypes.RUNNING)
                return runningTasksOnly
            }
            return []
        }
    }
}
</script>
